<template>
  <div v-if="!templateLoading" id="element-to-convert">
    <template v-if="this.viewType === 'open'">
      <a-button
        @click="
          $router.push({
            name: 'templateDataSystemBuilder',
            params: { menuItemId: $route.params.menuItemId },
          })
        "
        v-if="!$route.params.oldTemplateId"
        ><CaretLeftOutlined /> Back</a-button
      >
      <a-button
        @click="
          $router.push({
            name: 'templateDataSystemBuilder',
            params: {
              menuItemId: $route.params.menuItemId,
              instanceId: $route.params.instanceId,
              viewType: 'open',
              templateId: $route.params.oldTemplateId,
            },
          })
        "
        v-if="$route.params.oldTemplateId"
        ><CaretLeftOutlined /> Back</a-button
      >
      <div style="margin-bottom: 20px; float: right" v-if="template?.showDownloadPDF">
        <a-button type="primary" @click="generatePDFAction" :loading="downloadPDF.actionButton">Download PDF</a-button>
      </div>
      <a-divider />
    </template>

    <RecursiveColumns id="pdf-content" :lastDepth="false" v-if="template" :viewType="viewType" :template="template" @exportXls="exportXls" />
  </div>
  <template v-else>
    <loading-spinner />
  </template>
</template>

<script>
// import BlueprintFormLinkSharing from "@dataSystem/components/BlueprintFormLinkSharing";
import RecursiveColumns from '@templateDataSystem/views/components/RecursiveColumns';

import { SlugGetters } from '@/slug.store';

import { NewTemplatesActions, NewTemplatesGetters, NewTemplatesMutations } from '@templateDataSystem/shared/newTemplateDataSystem.store';
import _ from 'lodash';
import { FilterConditions } from '@templateDataSystem/shared/FilterConditions';
import { exportsApi } from '@dataSystem/api';
import { CaretLeftOutlined } from '@ant-design/icons-vue';

export default {
  name: 'TemplateDataSystemBuilder',
  components: {
    RecursiveColumns,
    CaretLeftOutlined,
    // BlueprintFormLinkSharing
  },
  props: ['menuItem'],
  updated() {},
  computed: {
    downloadPDF: NewTemplatesGetters.getDownloadPDF,
    downloadXLSX: NewTemplatesGetters.getDownloadXLSX,
    tenantUrl: SlugGetters.getTenantUrl,
    appUrl: SlugGetters.getAppUrl,
    subtenantUrl: SlugGetters.getSubtenantUrl,
    templateLoading: NewTemplatesGetters.getTemplateLoading,

    template() {
      return NewTemplatesGetters.getTemplate('main');
    },
  },
  data() {
    return {
      loading: false,
      htmlToPdfOptions: {
        margin: 5,

        filename: 'test-pdf',
      },

      viewType: this.$route.params.viewType ?? null,
      templateId: this.$route.params?.templateId ?? null,

      instanceId: this.$route.params.instanceId ?? null,

      isLinkSharingVisible: false,
    };
  },
  async created() {
    this.loading = true;
    const templateId = this.templateId === '' || !this.templateId ? this.menuItem.templateId : this.templateId;
    await NewTemplatesActions.init(templateId, this.instanceId);
    this.loading = false;
  },
  watch: {
    async downloadPDF(status) {
      if (status.actionButton) {
        await this.generatePDF();
        NewTemplatesMutations.SET_DOWNLOAD_PDF(true, false);
      }
    },
    '$route.params.viewType': function (value) {
      this.viewType = value;
    },
    '$route.params': async function (data) {
      const instanceId = data.instanceId ?? null;
      const templateId = data.templateId ?? this.menuItem?.templateId ?? null;

      this.instanceId = instanceId;
      await NewTemplatesActions.init(templateId, instanceId);
    },
    async downloadXLSX(xslx) {
      if (xslx.status && xslx.templateIdList.length && xslx.blueprintIdList.length) {
        await this.exportXls(xslx.templateIdList, xslx.blueprintIdList, xslx.columnsList, xslx.workbooksNames);
        NewTemplatesMutations.SET_DOWNLOAD_XLSX(false, [], []);
      }
    },
  },
  methods: {
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    async generatePDFAction() {
      NewTemplatesMutations.SET_DOWNLOAD_PDF(true, true);
    },
    async generatePDF() {
      const htmlObjectContent = document.getElementById('pdf-content').cloneNode(true);
      htmlObjectContent.querySelectorAll('button').forEach(button => button.remove());

      Array.from(htmlObjectContent.getElementsByTagName('table')).forEach(table => {
        this.deleteColumn(table);
      });
      await exportsApi.getPDF(`<body>${htmlObjectContent.outerHTML}</body>`, this.template._id, this.instanceId);
    },
    removeColumnContaining(value) {
      // Create a temporary DOM element to parse the HTML string
      const tempElement = document.createElement('div');
      tempElement.innerHTML = this.htmlString;

      // Find the header row and determine the index of the column to remove
      const headerRow = tempElement.querySelector('thead tr');
      const headers = headerRow ? headerRow.querySelectorAll('th') : [];
      let columnIndex = -1;

      headers.forEach((header, index) => {
        if (header.textContent.includes(value)) {
          columnIndex = index;
        }
      });

      // If a column index was found, remove that column from all rows
      if (columnIndex !== -1) {
        // Remove the column from the header row
        headers[columnIndex].remove();

        // Remove the column from each row in the tbody
        tempElement.querySelectorAll('tbody tr').forEach(row => {
          const cells = row.querySelectorAll('td');
          if (cells.length > columnIndex) {
            cells[columnIndex].remove();
          }
        });
      }

      // Update the reactive property with the new HTML
      this.updatedHTML = tempElement.innerHTML;
    },
    deleteColumn2(table) {
      // get headers
      const headers = Array.from(table.getElementsByTagName('tr')[0].getElementsByTagName('th'));

      // find column index
      const columnIndex = headers.findIndex(header => header.innerText === 'Actiuni');

      // use column index to delete cells
      if (columnIndex !== -1) {
        // -1 indicates that the item was not found
        Array.from(table.rows).forEach(row => {
          if (row.cells.length > columnIndex) {
            row.deleteCell(columnIndex);
          }
        });
      }
    },
    deleteColumn(table) {
      // Get all header cells (th) from the first row (thead)
      const headers = Array.from(table.getElementsByTagName('tr')[0].getElementsByTagName('th'));

      // Find the index of the column that has the header text 'Actiuni'
      const columnIndex = headers.findIndex(header => header.innerText.trim() === 'Actiuni');

      // Proceed if the column index is found
      if (columnIndex !== -1) {
        // Iterate over each row in the table
        Array.from(table.rows).forEach(row => {
          // Check if the row has enough cells
          if (row.cells.length > columnIndex) {
            // Remove the cell at the column index
            row.deleteCell(columnIndex);
          }
        });
      }
    },
    openLinkSharingDrawer() {
      this.isLinkSharingVisible = true;
    },
    closeLinkSharingDrawer() {
      this.isLinkSharingVisible = false;
    },
    async exportXls(templateIdList, blueprintIdList, columnsList, workbooksNames) {
      this.loading = true;
      const query = _.cloneDeep(NewTemplatesGetters.getLastQuery(templateIdList[0], blueprintIdList[0]));
      query.limit = null;
      const filterConditions = JSON.stringify(await FilterConditions(query.filterConditions));
      const queryStripParams = { ...query };
      const openInstance = NewTemplatesGetters.getMainInstance() ? JSON.stringify(NewTemplatesGetters.getMainInstance()) : null;
      await exportsApi.getAllXlsx(
        blueprintIdList[0],
        JSON.stringify(queryStripParams),
        filterConditions,
        columnsList[0],
        workbooksNames[0],
        openInstance
      );
      this.loading = false;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  #element-to-convert {
    margin-left: 0 !important;
  }
}
</style>
