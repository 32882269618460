import { dataSystemApplicationRoutes, dataSystemSubtenantRoutes, dataSystemRootRoutes } from '@dataSystem/dataSystem.routes';
import templateManagementRoutes from '@/apps/templateManagement/templateManagement.routes';
import { templateDataSystemRoutes, publicTemplateDataSystemRoutes } from '@/apps/templateDataSystem/templateDataSystem.routes';
import Homepage from './core/views/Homepage.vue';
import NotFound from './core/views/NotFound.vue';

import { includePrefixedRoutes } from './core/utils/include-routes';

import workflowRoutes from './apps/workflow/workflow.routes';
import fieldMapperRoutes from './apps/fieldMapper/fieldMapper.routes';

import dataProviderRoutes from './apps/dataProvider/dataProvider.routes';

import roleSubtenantRoutes from './apps/roleManagement/role.subtenant.routes';
import roleApplicationRoutes from './apps/roleManagement/role.application.routes';

import tenantsRoutes from './apps/tenants/tenants.routes';

import webhooksRoutes from './apps/webhooks/webhook.routes';

import accountRoutes from './apps/userManagement/account.routes';
import userRoutes from './apps/userManagement/user.routes';
import authRoutes from './apps/userManagement/auth.routes';

import menuBuilderRoutes from './apps/menuBuilder/menuBuilder.routes';

import smsManagementRoutes from './apps/smsManagement/smsManagement.routes';

import dataAuthRestRoutes from './apps/dataAuthRest/dataAuthRest.routes';

import unsubscribeRoutes from './apps/userManagement/unsubscribe.routes';

const routes = [
  {
    path: '/',
    name: 'Homepage',
    component: Homepage,
    meta: { isPublicPage: true },
  },
  ...authRoutes,
  ...userRoutes,

  ...includePrefixedRoutes('/public', dataSystemRootRoutes),

  ...includePrefixedRoutes('/unsubscribe', unsubscribeRoutes),
  ...includePrefixedRoutes('/publicTemplate', publicTemplateDataSystemRoutes),

  ...includePrefixedRoutes('/tenants', tenantsRoutes),

  ...includePrefixedRoutes('/account', accountRoutes, {
    includeTenantPath: true,
    includeApplicationPath: true,
    includeSubtenantPath: true,
  }),

  ...includePrefixedRoutes('/:tenantSlug/:appSlug/dataSystem', dataSystemApplicationRoutes),

  ...includePrefixedRoutes('/:tenantSlug/:appSlug/role-management', roleApplicationRoutes),

  ...includePrefixedRoutes('/:tenantSlug/:appSlug/field-mapper', fieldMapperRoutes),

  ...includePrefixedRoutes('/:tenantSlug/:appSlug/data-provider', dataProviderRoutes),

  ...includePrefixedRoutes('/:tenantSlug/:appSlug/workflow', workflowRoutes),

  ...includePrefixedRoutes('/:tenantSlug/:appSlug/:subtenantSlug/dataSystem/', dataSystemSubtenantRoutes),

  ...includePrefixedRoutes('/:tenantSlug/:appSlug/:subtenantSlug/role-management', roleSubtenantRoutes),

  ...includePrefixedRoutes('/:tenantSlug/:appSlug/:subtenantSlug/webhooks', webhooksRoutes),

  ...includePrefixedRoutes('/:tenantSlug/:appSlug/data-auth-rest', dataAuthRestRoutes),

  ...includePrefixedRoutes('/:tenantSlug/:appSlug/menu-builder', menuBuilderRoutes),

  ...includePrefixedRoutes('/:tenantSlug/:appSlug/template-management', templateManagementRoutes),

  ...includePrefixedRoutes('/:tenantSlug/:appSlug/:subtenantSlug/collections', templateDataSystemRoutes),

  ...includePrefixedRoutes('/:tenantSlug/:appSlug/:subtenantSlug/sms/', smsManagementRoutes),

  { path: '/:catchAll(.*)', component: NotFound },
];

export default routes;
