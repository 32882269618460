<template>
  <div v-if="!viewingMode">
    <a-input-number
      style="width: 100%"
      v-model:value="inputValue"
      :readOnly="readOnly"
      :min="typeof structureOptions.minValue === 'number' ? structureOptions.minValue : -Infinity"
      :max="typeof structureOptions.minValue === 'number' ? structureOptions.maxValue : +Infinity"
      :formatter="
        value => {
          if (structureOptions.decimalPlaces !== null && structureOptions.decimalPlaces !== 0) {
            return value.match('^-?\\d{0,12}\\.?\\d{0,' + structureOptions.decimalPlaces + '}');
          } else {
            return value.match('^-?\\d{0,12}');
          }
        }
      "
      :placeholder="inputPlaceholder"
      :required="field.isRequired"
      :size="inputSize"
      :auto-focus="autoFocus"
      :disabled="field.logic.calculation.isEnabled || readOnly || (field.isReadOnlyAdd && addNew) || (field.isReadOnlyEdit && !addNew)"
      @blur="e => $emit('blur', e)"
      @pressEnter="e => $emit('pressEnter', e)"
    />
  </div>

  <div v-else>
    <a-input-number
      style="width: 100%"
      v-model:value="rawValue"
      :readOnly="readOnly"
      :placeholder="inputPlaceholder"
      :required="field.isRequired"
      :size="inputSize"
      :auto-focus="autoFocus"
      :disabled="true"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import InputMixin from './InputMixin';

export default {
  mixins: [InputMixin],
  emits: ['pressEnter'],
  methods: {
    isPropValueValid() {
      return this.value === null || _.isFinite(Number(this.value));
    },
    isInputValueValid() {
      return this.inputValue === null || _.isFinite(Number(this.inputValue));
    },
    countDecimals(value) {
      if (Math.floor(value) === value) {
        return 0;
      }
      return value.toString().split('.')[1].length || 0;
    },
    isFloat(n) {
      return parseFloat(n.match(/^-?\d*(\.\d+)?$/)) > 0;
    },
  },
  watch: {
    inputValue: {
      deep: true,
      handler(value, oldValue) {
        if (value !== null && value !== '') {
          if (this.isFloat(value.toString()) && value.toString().includes('.')) {
            if (this.countDecimals(value) > this.structureOptions.decimalPlaces && oldValue !== null) {
              this.inputValue = parseFloat(this.inputValue.toString().slice(0, -1));
            }
          }
        }
      },
    },
  },
  mounted() {
    if (this.structureOptions.defaultValue && !this.inputValue) {
      this.inputValue = this.structureOptions.defaultValue;
    }
    this.emitInput();
  },
};
</script>
